.projects {
  width: 300px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active-project {
  border-radius: 5px;
  box-shadow: 0 0 5px 3px #8566e5;
  cursor: unset !important;
}

.prev,
.next {
  color: #7fd662;
  border: none;
  border-radius: 4px;
  font-size: 35px;
  padding: 5px 10px;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  opacity: 0.5;
  transition: all ease 0.5s;
  font-family: "Inter", sans-serif;
}

.prev:hover,
.next:hover {
  opacity: 1;
  transition: all ease 0.5s;
}

.container-mobile {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container-mobile .prev, .container-mobile .next {
    display: none;
}

@media (max-width: 1482px) {
  .container-mobile .prev, .container-mobile .next {
    display: block !important;
    background-color: unset !important

}
 .container-images {
  display: none !important;
 }
 .prev,
.next {
  padding: 20px 10px;
  margin-bottom: 80px;
}
}

@media (max-width: 823px) {
  .project-bio {
    text-align: center;
    padding: 0px 10px 20px 10px;
  }
}


.container-button {
  display: flex;
  width: 350px;
  justify-content: center;
  margin-top: 2rem;
}

.redirect::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: -2px;
  border-radius: 9px;
  background: linear-gradient(
    -45deg,
    #8566e5,
    #7fd662
  );
  background-size: 400%;
  animation: move infinite forwards 4s;
}
.redirect {
  display: flex;
  align-items: center;
  padding: 3px 5px 5px;
  justify-content: space-evenly;
  width: 140px;
  border-radius: 8px;
  position: relative;
  background-color: #121212;
  margin-top: 1rem;
  transition: 0.3s;
  font-weight: 600;
  font-size: 0.95rem;
  letter-spacing: 1.5px;
  height: 40px;
}

.redirect:hover {
  background-color: #272727;
  text-shadow: 1px 1px 3px #00bbc5;
}

.project-ul {
  font-size: 20px;
  text-align: center;
}

.project-link a:hover {
  background-color: #272727;
  text-shadow: 1px 1px 3px #00bbc5;
}

.projetos-h1 {
  padding: 40px;
  font-size: 25px
}

.project-link a:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: -2px;
  border-radius: 9px;
  background: linear-gradient(
    -45deg,
    rgba(147, 88, 247, 1),
    rgba(16, 215, 226, 1)
  );
  background-size: 400%;
  animation: move infinite forwards 4s;
}


.project-link a img {
  width: 20px;
  height: 20px;
}

.image {
  width: 400px;
  margin-bottom: 40px;
  border-radius: 5px;
}

.container-images {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.images {
  width: 175px;
  cursor: pointer;
}

.projetos {
  background-color: #151515;
  z-index: 0;
  position: relative;
  padding: 6.5% 15%;
  min-height: 35vh;
}
.stack,
.project-bio {
  font-size: 20px;
}

@media (max-width: 1325px) {
  .projetos {
    padding: 6.5%;
  }
}

@media (max-width: 880px) {
  .projetos {
    padding: 22% 0;
    text-align: center;
  }
}
