.show-right {
  animation: 0.5s forwards show-right;
}

@keyframes show-right {
  from {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.experiencia__lugares ul li.active {
    background-color: #151515;
    border-left: 2px solid #7fd662;
    font-weight: 600;
  }

/* .experiencia {
  padding: 6.5% 15%;
  min-height: 35vh;
} */


/* .experiencia__wrapper {
  display: flex;
} */

/* .experiencia__lugares {
  width: 200px;
  cursor: pointer;
} */

.experiencia__lugares ul {
  list-style: none;
  font-size: 1.1rem;
  padding: 0;
}

.experiencia__lugares ul li.active {
  background-color: #151515;
  border-left: 2px solid #7fd662;
  font-weight: 600;
}

.experiencia__lugares li {
  padding: 10px;
  text-align: center;
  border-left: 2px solid #272727;
  transition: background-color 0.5s ease;
}

.experiencia__lugares ul li:hover {
  background-color: #272727;
}

.experiencia__descricao {
  width: 60%;
  margin-left: 5rem;
  position: relative;
  line-height: 1.5rem;
}

.experiencia__descricao section.active {
  display: block;
}

.experiencia__descricao section.active.show-right {
  animation: 0.5s forwards show-right;
}

.experiencia__descricao h2 {
  font-size: 1.4rem;
  display: inline-block;
}

.experiencia h1 {
  font-size: 1.5rem;

}

.experiencia__descricao span {
  font-size: 0.95rem;
  position: absolute;
  right: 0;
  top: 5px;
  font-weight: bold;
}

.experiencia__descricao-local {
  margin: 2rem 0;
  font-size: 0.85rem;
  color: #7fd662 !important;
  font-weight: bold;
}

@keyframes show-right {
  from {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes show-down {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 1325px) {
  .experiencia {
    padding: 6.5%;
  }
}

@media (max-width: 880px) {
  .experiencia {
    padding: 22% 0;
    text-align: center;
  }

  .sobre-mim {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .experiencia__wrapper {
    flex-direction: column;
    align-items: center;
  }

  .experiencia__lugares {
    width: 250px;
  }
  .experiencia__lugares li {
    border-left: none;
    border-bottom: 2px solid #272727;
  }

  .experiencia__lugares ul li.active {
    background-color: #151515;
    border-left: none;
    border-bottom: 2px solid #7fd662;
    font-weight: 600;
  }

  .experiencia__descricao {
    margin-left: 0;
    margin-top: 2rem;
    min-height: 46vh;
    width: 90%;
  }
  .experiencia__descricao span {
    display: block;
    position: inherit;
  }

}


