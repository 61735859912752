* {
  box-sizing: border-box;
}

body {
  /* color: #c4c4c4; */
  margin: 0;
  border: 0;
  background-color: #121212;
  font-family: "Inter", sans-serif;
  letter-spacing: 1px;
  color: white;
}

h1,
h2,
p {
  margin: 0;
}

h1 {
  text-transform: uppercase;
}

h2 {
  font-size: 1rem;
}

section h1 {
  margin-bottom: 2rem;
}

a {
  text-decoration: none;
  color: white;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #75ba75;
}
