.principal {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 60vh;
  padding: 6.5% 15%;
}

.principal__left {
  width: 50%;
  margin: 5rem 0;
  margin-top: -200px !important;
}

.principal__right {
  width: 40%;
  margin: 2rem 0;
  margin-top: -200px !important;
}

.principal__right img {
  max-width: 100%;
}


.principal .scroll p {
  padding: 1rem 0;
  display: inline-block;
  cursor: pointer;
}

.principal .scroll img {
  animation: scroll-down 3s infinite forwards;
  cursor: pointer;
}

.principal .scroll {
  position: absolute;
  bottom: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.principal h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

.principal span {
  font-size: 3rem;
  text-transform: uppercase;
  color: #75ba75;
  font-weight: 600;
  letter-spacing: 3px;
}

.principal__input-cursor {
  width: 3px;
  height: 42px;
  background-color: white;
  display: inline-block;
  position: relative;
  top: 3px;
  animation: blink 0.6s infinite alternate;
}

.principal__botao {
  display: flex;
  width: 350px;
  justify-content: space-between;
}

.principal__botao-linkedin,
.principal__botao-github {
  display: flex;
  align-items: center;
  padding: 3px 5px 5px;
  justify-content: space-evenly;
  width: 140px;
  border-radius: 8px;
  position: relative;
  background-color: #121212;
  margin-top: 1rem;
  transition: 0.3s;
  font-weight: 600;
  font-size: 0.95rem;
  letter-spacing: 1.5px;
  height: 40px;
}

.principal__botao-linkedin::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: -2px;
  border-radius: 9px;
  background: linear-gradient(
    -45deg,
    #8566e5,
    #7fd662
  );
  background-size: 400%;
  animation: move infinite forwards 4s;
}

.principal__botao-linkedin:hover,
.principal__botao-github:hover {
  background-color: #272727;
  text-shadow: 1px 1px 3px #00bbc5;
}

.principal__botao-github::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: -2px;
  border-radius: 9px;
  background: linear-gradient(
    150deg,
    rgba(210, 64, 116, 1),
    rgba(101, 24, 180, 1)
  );
  background-size: 400%;
  animation: move infinite forwards 4s;
}

.principal__botao-linkedin p,
.principal__botao-github p,
.principal__botao-github img {
  padding: 9px 0 5px;
}

.principal__botao-linkedin img {
  margin-right: 8px;
}

.principal__botao-linkedin img,
.principal__botao-github img {
  width: 22px;
}

@media (max-width: 1325px) {
  .principal h1 {
    font-size: 1rem;
  }
  .principal span {
    font-size: 2.2rem;
  }

  .principal .principal__input-cursor {
    height: 35px;
  }

  .principal {
    padding: 6.5%;
  }
}

@media (max-width: 880px) {
  .principal {
    padding: 22% 0;
    text-align: center;
  }

  .principal {
    flex-direction: column;
  }

  .principal__left {
    width: 90%;
  }

  .principal__right {
    display: none;
  }

  .sobre-mim {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .sobre-mim__foto {
    max-width: 80%;
  }

  .sobre-mim__descricao {
    width: 80%;
  }

  .principal__botao {
    width: 320px;
    margin: 1rem auto;
  }
}

@media (max-width: 460px) {
  .principal span {
    font-size: 1.8rem;
  }

  .principal .principal__input-cursor {
    height: 30px;
  }

  .principal__botao-linkedin,
  .principal__botao-github {
    width: 110px;
  }

  .principal__botao {
    width: auto;
    justify-content: space-around;
  }
}

@keyframes scroll-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -15px, 0);
  }
  30% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }
}

@keyframes move {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
