.sobre-mim__descricao-buttons-curriculo,
.sobre-mim__descricao-buttons-email {
  transition: 0.3s;
}


.sobre-mim__descricao-buttons-email::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: -2px;
  border-radius: 9px;
  background: linear-gradient(
    -45deg,
    #8566e5,
    #7fd662
  );
  background-size: 400%;
  animation: move infinite forwards 4s;
}

.sobre-mim__descricao-buttons-curriculo:hover,
.sobre-mim__descricao-buttons-email:hover {
  background-color: #272727;
  text-shadow: 1px 1px 3px #7fd662;
}

@keyframes move {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.sobre-mim__descricao-buttons-curriculo::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: -2px;
  border-radius: 9px;
  background: linear-gradient(
    150deg,
    rgba(210, 64, 116, 1),
    rgba(101, 24, 180, 1)
  );
  background-size: 400%;
  animation: move infinite forwards 4s;
}

.sobre-mim__foto img {
  border-left: 5px solid #7fd662;
  filter: opacity(55%);
  transition: 0.4s;
}

.sobre-mim__foto img:hover {
  filter: opacity(70%);
  border-left: 5px solid #8566e5;
}

@keyframes show-right {
  from {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes show-down {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 1325px) {
  .sobre-mim {
    padding: 6.5%;
  }
}

@media (max-width: 880px) {
  .sobre-mim {
    padding: 22% 0;
    text-align: center;
  }

  .sobre-mim {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .sobre-mim__foto {
    max-width: 80%;
  }

  .sobre-mim__descricao {
    width: 80%;
  }

  .sobre-mim__descricao-buttons {
    width: 320px;
    margin: 1rem auto;
  }

}

@media (max-width: 460px) {
  .sobre-mim__descricao-buttons-curriculo,
  .sobre-mim__descricao-buttons-email {
    width: 110px;
  }

  .sobre-mim__descricao-buttons {
    width: auto;
    justify-content: space-around;
  }
}
